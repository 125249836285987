import React from "react";
import styled from "styled-components";

/**
 * Local Components
 */
import {
  hoverChange,
  themeChange,
  hoverCustomProperty,
} from "../functions/util";
import { CramdynMarquee } from "../components/home-logo-marquee-wrapper";

/**
 * FourOhFour Component
 *
 * @param {Object} props
 * @param {Object} props.path
 */
export default function FourOhFour({ path }) {
  React.useEffect(() => {
    themeChange("light");
    return () => themeChange(null);
  });

  return (
    <Wrapper404>
      <CramdynMarquee
        color="white"
        fourohfour={true}
        onMouseEnter={() => {
          hoverCustomProperty("custom-content", "You're Lost");
          hoverChange("text-hover");
        }}
        onMouseLeave={() => {
          hoverCustomProperty(null);
          hoverChange(null);
        }}
      />
    </Wrapper404>
  );
}

const Wrapper404 = styled.div`
  height: 100vh;
  min-height: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${({ theme }) => theme.colors.peach};
`;
